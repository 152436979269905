import React from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import useToggleAndOutClick from 'use-toggle-and-outclick';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaCheck } from 'react-icons/fa';

// Styles
import './TopNavigation.scss';

const TopNavigation = ({ user, isSuper, orderMen }) => {
  const [
    isDropReportsPlanOpened,
    dropReportsPlanRef,
    toggleDropReportsPlan,
    closeExplicitlyReportsPlan,
  ] = useToggleAndOutClick();

  const [
    isDropReportsProlongOpened,
    dropReportsProlongRef,
    toggleDropReportsProlong,
    closeExplicitlyReportsProlong,
  ] = useToggleAndOutClick();

  const [isDropTuOpened, dropTuRef, toggleDropTu, closeExplicitlyTu] = useToggleAndOutClick();

  return (
    <nav className="nav">
      <ul className="nav__list">
        <li className="nav__list-item">
          <NavLink
            exact
            to="/dashboard"
            className="nav__list-item-link"
            activeClassName="nav__list-item-link_active"
          >
            План-график
          </NavLink>
        </li>
        <li className="nav__list-item">
          <NavLink
            to="/employment"
            className="nav__list-item-link"
            activeClassName="nav__list-item-link_active"
          >
            Занятость работников
          </NavLink>
        </li>

        {(user.userRole === 1 ||
          user.userRole === 5 ||
          user.userRole === 6 ||
          user.userRole === 7 ||
          user.userRole === 8 ||
          user.userRole === 10 ||
          user.userRole === 13 ||
          isSuper) && (
          <li className="nav__list-item">
            <NavLink
              to="/rmm/0"
              className="nav__list-item-link"
              activeClassName="nav__list-item-link_active"
            >
              СРБ
            </NavLink>
          </li>
        )}

        {(user.userRole === 1 ||
          user.userRole === 5 ||
          user.userRole === 6 ||
          user.userRole === 7 ||
          user.userRole === 8 ||
          user.userRole === 10 ||
          user.userRole === 13 ||
          isSuper) && (
          <li className="nav__list-item">
            <NavLink
              to="/nrb/0"
              className="nav__list-item-link"
              activeClassName="nav__list-item-link_active"
            >
              НРБ
            </NavLink>
          </li>
        )}

        <li className="nav__list-item">
          <button
            type="button"
            className={cx('nav__list-item-link', {
              'nav__list-item-link_active': isDropReportsPlanOpened,
            })}
            onClick={toggleDropReportsPlan}
          >
            Своды <span className="nav__list-item-link-icon" />
          </button>

          {isDropReportsPlanOpened && (
            <nav className="nav nav_sub-nav" ref={dropReportsPlanRef}>
              <div className="nav__scroll-wrap">
                <ul className="nav__list">
                  <li className="nav__list-item">
                    <NavLink
                      to="/dispatch"
                      className="nav__list-item-link"
                      activeClassName="nav__list-item-link_active"
                      onClick={closeExplicitlyReportsPlan}
                    >
                      <FaCheck className="icon" /> Диспетчерский отчет
                    </NavLink>
                  </li>
                  <li className="nav__list-item">
                    <NavLink
                      to="/report-add"
                      className="nav__list-item-link"
                      activeClassName="nav__list-item-link_active"
                      onClick={closeExplicitlyReportsPlan}
                    >
                      <FaCheck className="icon" /> Свод доп. работ
                    </NavLink>
                  </li>
                  <li className="nav__list-item">
                    <NavLink
                      to="/report-co-work"
                      className="nav__list-item-link"
                      activeClassName="nav__list-item-link_active"
                      onClick={closeExplicitlyReportsPlan}
                    >
                      <FaCheck className="icon" /> Свод работ соисполнителей
                    </NavLink>
                  </li>

                  {(user.userRole === 6 || user.userRole === 10 || isSuper) && (
                    <li className="nav__list-item">
                      <NavLink
                        to="/report"
                        className="nav__list-item-link"
                        activeClassName="nav__list-item-link_active"
                        onClick={closeExplicitlyReportsPlan}
                      >
                        <FaCheck className="icon" /> Текущий отчет
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </nav>
          )}
        </li>

        {((user.userRole > 5 && user.userRole < 11) || user.userRole === 1 || isSuper) && (
          <li className="nav__list-item">
            <button
              type="button"
              className={cx('nav__list-item-link', {
                'nav__list-item-link_active': isDropReportsProlongOpened,
              })}
              onClick={toggleDropReportsProlong}
            >
              Отчеты <span className="nav__list-item-link-icon" />
            </button>

            {isDropReportsProlongOpened && (
              <nav className="nav nav_sub-nav" ref={dropReportsProlongRef}>
                <div className="nav__scroll-wrap">
                  <ul className="nav__list">
                    {orderMen &&
                      Array.isArray(orderMen) &&
                      orderMen.map(item => (
                        <li key={item.id} className="nav__list-item">
                          <a
                            href={`${process.env.REACT_APP_API_URL}excelreports/_aplan_b${item.id}/`}
                            className="nav__list-item-link"
                            onClick={closeExplicitlyReportsProlong}
                          >
                            Отчет план-график {item.name}
                          </a>
                        </li>
                      ))}

                    <li className="nav__list-item nav__list-item_divider" />
                    <li className="nav__list-item">
                      <a
                        href={`${process.env.REACT_APP_API_URL}excelreports/_aprolong/`}
                        className="nav__list-item-link"
                        onClick={closeExplicitlyReportsProlong}
                      >
                        Продления ремонтов
                      </a>
                    </li>
                    <li className="nav__list-item">
                      <a
                        href={`${process.env.REACT_APP_API_URL}excelreports/_arepair/`}
                        className="nav__list-item-link"
                        onClick={closeExplicitlyReportsProlong}
                      >
                        Вывод в ремонт
                      </a>
                    </li>
                    <li className="nav__list-item">
                      <a
                        href={`${process.env.REACT_APP_API_URL}excelreports/_armm/`}
                        className="nav__list-item-link"
                        onClick={closeExplicitlyReportsProlong}
                      >
                        Отчет по РММ
                      </a>
                    </li>
                    <li className="nav__list-item nav__list-item_divider" />

                    {orderMen &&
                      Array.isArray(orderMen) &&
                      orderMen.map(item => (
                        <li key={item.id} className="nav__list-item">
                          <a
                            href={`${process.env.REACT_APP_API_URL}excelreports/_azprolong_b${item.id}/`}
                            className="nav__list-item-link"
                            onClick={closeExplicitlyReportsProlong}
                          >
                            Журнал учета сроков (продлений) ремонтов {item.name}
                          </a>
                        </li>
                      ))}

                    {false &&
                      orderMen &&
                      Array.isArray(orderMen) &&
                      orderMen.map(item => (
                        <React.Fragment key={item.id}>
                          <li className="nav__list-item nav__list-item_divider" />
                          <li className="nav__list-item">
                            <a
                              href={`${process.env.REACT_APP_API_URL}excelreports/_aprolongReport_b${item.id}/`}
                              className="nav__list-item-link"
                              onClick={closeExplicitlyReportsProlong}
                            >
                              Сводный отчет продлений ремонтов {item.name}
                            </a>
                          </li>
                        </React.Fragment>
                      ))}
                  </ul>
                </div>
              </nav>
            )}
          </li>
        )}

        <li className="nav__list-item">
          <NavLink
            to="/mtr"
            className="nav__list-item-link"
            activeClassName="nav__list-item-link_active"
          >
            Мтр
          </NavLink>
        </li>
        <li className="nav__list-item">
          <NavLink
            to="/rules"
            className="nav__list-item-link"
            activeClassName="nav__list-item-link_active"
          >
            Регламенты
          </NavLink>
        </li>
        <li className="nav__list-item">
          <button
            type="button"
            className={cx('nav__list-item-link', {
              'nav__list-item-link_active': isDropTuOpened,
            })}
            onClick={toggleDropTu}
          >
            ТУ <span className="nav__list-item-link-icon" />
          </button>

          {isDropTuOpened && (
            <nav className="nav nav_sub-nav" ref={dropTuRef}>
              <div className="nav__scroll-wrap">
                <ul className="nav__list">
                  <li className="nav__list-item">
                    <NavLink
                      to="/despatch"
                      className="nav__list-item-link"
                      activeClassName="nav__list-item-link_active"
                      onClick={closeExplicitlyTu}
                    >
                      Подача заявки
                    </NavLink>
                  </li>
                  <li className="nav__list-item">
                    <NavLink
                      to="/transport-site"
                      className="nav__list-item-link"
                      activeClassName="nav__list-item-link_active"
                      onClick={closeExplicitlyTu}
                    >
                      Транспорт
                    </NavLink>
                  </li>
                  <li className="nav__list-item">
                    <NavLink
                      to="/drivers"
                      className="nav__list-item-link"
                      activeClassName="nav__list-item-link_active"
                      onClick={closeExplicitlyTu}
                    >
                      Водители
                    </NavLink>
                  </li>
                  <li className="nav__list-item">
                    <NavLink
                      to="/tscontrol"
                      className="nav__list-item-link"
                      activeClassName="nav__list-item-link_active"
                      onClick={closeExplicitlyTu}
                    >
                      Контроль поездок
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav>
          )}
        </li>
        {/*<li className="nav__list-item">*/}
        {/*  <NavLink*/}
        {/*    to="/filemanager"*/}
        {/*    className="nav__list-item-link"*/}
        {/*    activeClassName="nav__list-item-link_active"*/}
        {/*  >*/}
        {/*    Файл-менеджер*/}
        {/*  </NavLink>*/}
        {/*</li>*/}
      </ul>
    </nav>
  );
};

TopNavigation.propTypes = {
  user: PropTypes.shape({
    userRole: PropTypes.number,
  }).isRequired,
  isSuper: PropTypes.bool.isRequired,
  orderMen: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  user: state.mainReducer.user,
});

export default connect(mapStateToProps)(TopNavigation);
